<template>
  <div
    class="c-d-flex ui-flex-col c-bg-primary-200"
    style="min-height: 100%;"
  >
    <!-- Logo e stepper -->
    <div class="c-container h-header">
      <div class="c-row ui-pt-8">
        <!-- Logo Estratégia -->
        <div class="c-col-2 sm:c-col-2 md:c-col-2">
          <estrategia-logo />
        </div>

        <!-- Stepper -->
        <div class="c-col-2 sm:c-col">
          <p-stepper />
        </div>
      </div>
    </div>

    <!-- Content -->
    <div class="bg-image-lateral ui-flex-1 c-container c-d-flex c-center">
      <div class="ui-w-full c-row c-horizontal-center">
        <router-view class="c-col-full ui-rounded-lg c-bg-white ui-px-4 md:ui-px-8 ui-py-8 ui-overflow-hidden" />
      </div>
    </div>

    <div class="ui-w-full c-d-flex c-horizontal-center">
      <div class="bg-image-bottom" />
    </div>
  </div>
</template>

<script>
import PStepper from '@/components/p-stepper';
import EstrategiaLogo from '@/components/estrategia-logo';

export default {
  components: { EstrategiaLogo, PStepper },
};
</script>

<style scoped lang="scss">
@include c-mq(sm) {
  .bg-image-lateral {
    background-size: 407px; /* medida do figma */
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-image: url("~@/assets/images/register-illustration.svg");
    background-position: 100%;
  }
}

.bg-image-bottom {
  width: 278px; /* medida do figma */
  height: 45vw;
  background-image: url("~@/assets/images/register-illustration.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  @include c-mq(sm) {
    display: none;
  }
}

.h-header {
  height: var(--header-height);
}
</style>
